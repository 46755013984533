import { Classroom, Segment } from '@campus/commons';

class ManagerClass {
  id?: string;

  name?: string;

  segment?: Segment;

  classes?: Classroom[];

  static fromJson(data: any): ManagerClass {
    const managerClass = new ManagerClass();

    managerClass.id = data.Id;
    managerClass.name = data.Name;
    managerClass.segment = Segment.fromJson(data.Segment);
    managerClass.classes =
      data?.Classes?.map((i) => Classroom.fromJson(i)) ?? [];

    return managerClass;
  }
}

export default ManagerClass;
