import { Api } from '@campus/auth';
import { Response } from '@campus/commons';
import ManagerClass from 'models/ManagerClass';
import ManagerGradeClassModel from 'models/ManagerGradeClass';
import qs from 'qs';

export const listManagerClasses = async (): Promise<
  Response<ManagerClass[]>
> => {
  try {
    const response = await Api.INSTANCE.getApi().get(`/manager/class`);

    return Response.fromData(
      response.data.map((item) => ManagerClass.fromJson(item)),
      response.status
    );
  } catch (e: any) {
    return Response.error(e);
  }
};

export const listManagerGradeClasses = async (
  gradeIds?: string[]
): Promise<Response<ManagerGradeClassModel[]>> => {
  try {
    const response = await Api.INSTANCE.getApi().get(`/manager/class/detail`, {
      params: { gradeIds },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat' })
    });

    return Response.fromData(
      response.data.map((item) => ManagerGradeClassModel.fromJson(item)),
      response.status
    );
  } catch (e: any) {
    return Response.error(e);
  }
};
