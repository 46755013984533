export interface ManagerGradeClassModel {
  Id?: string;
  Name?: string;
  Segment?: Segment;
  AccessPerDay?: number;
  AverageViewCount?: number;
  ContentCount?: number;
  StudentAccessPercentage?: number;
  StudentCount?: number;
  Classes?: Class[];
  Disciplines?: Discipline[];
  EvaluationTypes?: ManagerEvaluationType[];
  Teachers?: ManagerTeacher[];
}

export class Segment {
  Id?: string;
  Name?: string;
  Suffix?: string;

  static fromJson(data: any): Segment {
    return {
      Id: data.Id,
      Name: data.Name,
      Suffix: data.Suffix,
    };
  }
}

export class Class {
  Id?: string;
  Name?: string;
  StudentCount?: number;

  static fromJson(data: any): Class {
    return {
      Id: data.Id,
      Name: data.Name,
      StudentCount: data.StudentCount,
    };
  }
}

export class Discipline {
  Id?: string;
  Name?: string;
  ContentCount?: number;
  ContentCountPercentage?: number;

  static fromJson(data: any): Discipline {
    return {
      Id: data.Id,
      Name: data.Name,
      ContentCount: data.ContentCount,
      ContentCountPercentage: data.ContentCountPercentage
    };
  }
}

export class ManagerTeacher {
  Disciplines?: TeacherDiscipline[];
  Biography?: string;
  Name?: string;
  ImageUrl?: string;
  Id?: string;

  static fromJson(data: any): ManagerTeacher {
    return {
      Disciplines: data.Disciplines?.map((d: any) => TeacherDiscipline.fromJson(d)) ?? [],
      Biography: data.Biography,
      Name: data.Name,
      ImageUrl: data.ImageUrl,
      Id: data.Id,
    };
  }
}

export class ManagerEvaluationType {
  Id?: string;
  Name?: string;
  PluralName?: string;
  ContentCount?: number;
  EvaluationTypeColor?: string;

  static fromJson(data: any): ManagerEvaluationType {
    return {
      Id: data.Id,
      Name: data.Name,
      PluralName: data.PluralName,
      ContentCount: data.ContentCount,
      EvaluationTypeColor: data.EvaluationTypeColor,
    };
  }
}

export class TeacherDiscipline {
  Id?: string;
  Name?: string;
  GradeId?: string;
  Grade?: Grade;
  ContentCount?: number;

  static fromJson(data: any): TeacherDiscipline {
    return {
      Id: data.Id,
      Name: data.Name,
      GradeId: data.GradeId,
      Grade: data.Grade ? Grade.fromJson(data.Grade) : undefined,
      ContentCount: data.ContentCount,
    };
  }
}

export class Grade {
  Id?: string;
  Name?: string;

  static fromJson(data: any): Grade {
    return {
      Id: data.Id,
      Name: data.Name,
    };
  }
}

export default class ManagerGradeClass {
  public static fromJson(data: any): ManagerGradeClassModel {
    const managerGrade: ManagerGradeClassModel = {
      Id: data.Id,
      Name: data.Name,
      Segment: data.Segment ? Segment.fromJson(data.Segment) : undefined,
      Classes: data?.Classes?.map((i) => Class.fromJson(i)) ?? [],
      AccessPerDay: data.AccessPerDay,
      AverageViewCount: data.AverageViewCount,
      ContentCount: data.ContentCount,
      StudentAccessPercentage: data.StudentAccessPercentage,
      StudentCount: data.StudentCount,
      Disciplines: data.Disciplines?.map((d) => Discipline.fromJson(d)) ?? [],
      EvaluationTypes: data.EvaluationTypes?.map((e) => ManagerEvaluationType.fromJson(e)) ?? [],
      Teachers: data.Teachers?.map((t) => ManagerTeacher.fromJson(t)) ?? []
    };
  
    return managerGrade;
  }
  public static toJson(value: ManagerGradeClassModel[]): string {
    return JSON.stringify(value);
  }
}
