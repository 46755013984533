import React from 'react';
import { Content, HeaderManager, MyTools, Skeleton } from '@campus/components';
import { useNavigate } from 'react-router-dom';
import { useWindowDimensions } from '@campus/commons';

import PedagogicalPlanningIcon from '@campus/components/assets/icons/pedagogical-planning.svg';
import VestibularIcon from '@campus/components/assets/icons/vestibular-icon.svg';
import ReportIcon from '@campus/components/assets/icons/report-icon.svg';
import skillManagementIcon from '@campus/components/assets/icons/skill-management.svg';

import {
  listManagerClasses,
  listManagerGradeClasses
} from 'services/managerClass';
import ManagerClass from 'models/ManagerClass';
import { ManagerGradeClassModel } from 'models/ManagerGradeClass';

import SearchPerson from './components/SearchPerson';
import ClassCard from './components/ClassCard';
import { ContentStyled, Line, Title, Root } from './styles';

const Home: React.FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(true);
  const { breakpoint } = useWindowDimensions();

  const tools = [
    {
      name: 'Planejamento Pedagógico',
      click: () => {
        window.open('/planning');
      },
      icon: {
        src: PedagogicalPlanningIcon,
        alt: 'Planejamento Pedagógico',
        bgColor: '#F0F0F0'
      }
    },
    {
      name: 'Campus Vestibular',
      click: () => {
        navigate('/vestibular');
      },
      icon: {
        src: VestibularIcon,
        alt: 'Campus Vestibular',
        bgColor: '#F0F0F0'
      }
    },
    {
      name: 'Relatório do Planejamento',
      click: () => {
        navigate('/planning/launch-report');
      },
      icon: {
        src: ReportIcon,
        alt: 'Relatório do Planejamento Pedagógico',
        bgColor: '#F0F0F0'
      }
    },
    {
      name: 'Gestão das habilidades',
      click: () => {
        navigate('/planning/skill-management');
      },
      icon: {
        src: skillManagementIcon,
        alt: 'Gestão de Habilidades',
        bgColor: '#F0F0F0'
      }
    }
  ];

  const [classesBySegment, setClassesBySegment] = React.useState<
    ManagerClass[]
  >([]);

  React.useEffect(() => {
    setLoading(true);
    const getData = async () => {
      const result = await listManagerClasses();
      if (result.data) {
        setClassesBySegment(result.data);
      }
    };

    getData();
  }, []);

  const [gradesBySegment, setGradesBySegment] = React.useState<
    ManagerGradeClassModel[]
  >([]);

  React.useEffect(() => {
    const loadAllGrades = async () => {
      if (classesBySegment.length === 0) return;

      const allGradeIds = classesBySegment.map((gradeItem) => gradeItem.id);
      const result = await listManagerGradeClasses(allGradeIds);

      if (result.data) {
        setGradesBySegment(result.data);
      }

      setLoading(false);
    };

    if (classesBySegment.length > 0) {
      loadAllGrades();
    }
  }, [classesBySegment]);

  return (
    <Root>
      <HeaderManager
        title="Acompanhamento Geral"
        subTitle="Acompanhe os indicadores gerais sobre as séries"
      />
      <ContentStyled>
        <MyTools tools={tools} breakpoint={breakpoint} />
        <Line />
      </ContentStyled>
      <SearchPerson />
      {gradesBySegment.map((grade) => (
        <Content key={grade.Segment.Name}>
          <Title>{grade.Segment.Name}</Title>
          <ClassCard grade={grade} />
        </Content>
      ))}
      {loading && (
        <Content>
          {Array(2)
            .fill(0)
            .map((_, index) => (
              <div className="mb-5" key={index}>
                <Skeleton height="40px" width="100%" className="mb-3" />
                <Skeleton height="202px" width="100%" />
              </div>
            ))}
        </Content>
      )}
    </Root>
  );
};

export default Home;
