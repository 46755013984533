import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { SideMenu, Accordion } from '@campus/components';
import { MenuHeader } from '@campus/commons';

import { PedagogicalPlanningLink } from '@campus/pedagogical-planning';

import { listManagerClasses } from 'services/managerClass';
import ManagerClass from 'models/ManagerClass';
import CustomAccordion from './components/CustomAccordion';

import { AccordionStyled, DivGrades, Lists } from './styles';

const Menu: React.FC<React.HTMLAttributes<HTMLElement>> = (props) => {
  const [loading, setLoading] = useState(true);
  const [gradesBySegment, setGradesBySegment] = useState<ManagerClass[]>([]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const result = await listManagerClasses();

      if (result.data) {
        setGradesBySegment(result.data);
      }

      setLoading(false);
    };

    getData();
  }, []);

  return (
    <SideMenu {...props}>
      <MenuHeader />
      <Link to="/">
        <SideMenu.Option icon="home">Página inicial</SideMenu.Option>
      </Link>
      <Lists>
        {loading ? (
          <SideMenu.List title="">
            <SideMenu.ListItemSkeleton />
            <SideMenu.ListItemSkeleton />
            <SideMenu.ListItemSkeleton />
          </SideMenu.List>
        ) : (
          gradesBySegment.map((item, i) => (
            <AccordionStyled key={item.id}>
              <CustomAccordion
                eventKey={item.id}
                lastItem={gradesBySegment.length - 1 === i}
                firstItem={i === 0}
              >
                {item.segment.name.toUpperCase()} |{' '}
                <strong>{item.name.toUpperCase()}</strong>
              </CustomAccordion>
              <Accordion.Collapse eventKey={item.id}>
                <DivGrades>
                  {item.classes.map((classItem) => (
                    <Link key={classItem.id} to={`/class/${classItem.id}`}>
                      <SideMenu.ListItem title={classItem.name} />
                    </Link>
                  ))}
                </DivGrades>
              </Accordion.Collapse>
            </AccordionStyled>
          ))
        )}

        <PedagogicalPlanningLink />
      </Lists>
    </SideMenu>
  );
};

export default Menu;
